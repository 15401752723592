import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("@/views/landing-page/LandingPage.vue"),
  },
  {
    path: '/app/firstConnexion',
    name: 'FirstConnexion',
    component: () => import('@/views/app/MagicLinkConnexion.vue'),
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/pages/UnauthorizedView.vue')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/pages/MaintenanceView.vue')
  },
  {
    path: '/successPayment',
    name: 'success',
    component: () => import('@/views/pages/SuccessPayment.vue')
  },
  {
    path: '/errorPayment',
    name: 'error',
    component: () => import('@/views/pages/ErrorPayment.vue')
  },
  {
    path: '/termes-et-conditions',
    name: 'termes-et-conditions',
    component: () => import('@/views/pages/TermesConditions.vue')
  },
  {
    path: '/conditions-generales-vente',
    name: 'conditions-vente',
    component: () => import('@/views/pages/ConditionsVente.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/pages/404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  try {
    if (to.name === 'NotFound') {
      next('/404')
    } else {
      next()
    }
  } catch (error) {
    console.error('Erreur lors de la vérification de la session utilisateur :', error)
    next('/')
  }
})

export default router
