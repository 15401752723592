// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
// Vuetify
import { createVuetify } from "vuetify";
export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#6200EE', // Couleur principale
          subtitle: '#4F4F4F', // Couleur pour les sous-titres
          title: '#1F1F47', // Couleur pour les titres
        },
      },
    },
  },
})

