<template>
  <v-app>
    <v-main class="bg-grey-lighten-3">
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup></script>

<style>
.background-gradient {
  background: linear-gradient(to bottom, #fff9ed, #fff9ed);
}

.section-title {
  margin-top: 40px;
  color: #1f1f47;
  font-weight: bold;
  text-align: center;
  font-size: 40px;
}
</style>
